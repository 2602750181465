<style scoped>
  .box {
    float: left; width: 30px; height: 36px;
  }
  .blue {
    border: 2px solid #06c;
  }
  .red {
    border: 2px solid #900;
  }
  .green {
    border: 2px solid #090;
  }
  .redright {
    border-right: 2px solid #900;
  }
  .blueright {
    border-right: 2px solid #06c;
  }
  .greenright {
    border-right: 2px solid #090;
  }
  .redleft {
    border-left: 2px solid #900;
  }
  .blueleft {
    border-left: 2px solid #06c;
  }
  .greenleft {
    border-left: 2px solid #090;
  }

</style>
<template>
  <div v-if="one && one.n" style="width: 62px;">
    <!-- first line -->
    <div class="box" :class="{ blueright: color === 'blue', redright: color === 'red', greenright: color === 'green' }">
    </div>
    <div class="box">
    </div>
    <!-- second line -->
    <div style="float: left;" v-if="one.a === '😟'">
      <div class="box" :class="{ blue: color === 'blue', red: color === 'red', green: color === 'green' }" style=" border-right: none; border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;">
      </div>
      <div class="box">
      </div>
    </div>
    <div v-else-if="one.a === '😊'">
      <div class="box">
      </div>
      <div class="box" :class="{ blue: color === 'blue', red: color === 'red', green: color === 'green' }"  style="border-left: none; border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;">
      </div>
    </div>
    <div v-else>
      <div class="box">
      </div>
      <div class="box" :class="{ blueleft: color === 'blue', redleft: color === 'red', greenleft: color === 'green' }">
      </div>
    </div>
    <!-- third line -->
    <div class="box":class="{ blueright: color === 'blue', redright: color === 'red', greenright: color === 'green' }">
    </div>
    <div class="box">
    </div>
  </div>
</template>
<script>

export default {
  name: 'Oneline',
  data() {
    return {
      data: ''
    }
  },
  props: ['one', 'color'],
  components: {
    //    mainmenu,
  },
  mounted() {
    // this.get()
  },
  methods: {
    makeTextGo () {

    },
  },
  watch: {
  /*
    order() {
      this.makeTextGo()
    }
    */
  }
}
</script>
