//yes

module.exports = [
  'Estonian song and dance festival',
  'Too much sex',
  'Electric cars',
  'Birth control',
  'Vaccination (in general)',
  'Feminism',
  'Gay parade',
  'Bicycle',
  'Arabs',
  'World Health Organisation',
  'Varro Vooglaid',
  'Capitalism',
  'Greta Thundberg',
  'Hijab',
  'LGBT',
  'Covid vaccination',
  'Drugs (narcotics)',
  'European Union',
  'Pronouns / gender identities',
  'Car',
  'George Soros',
  'Trans persons',
  'Marijuana',
  'Bill Gates',
  'Moslems',
  'MDMA',
  'Sex change',
  'Elon Musk',
  'Communism',
  'LSD',
  'Joe Biden',
  'Alexey Navalny',
  'Womans right for abortion',
  'Send weapons to Ukraine',
  'White power',
  'Benjamin Netanyahu',
  'Cocaine',
  'LGBTQIA+',
  'Volodymyr Zelenskyy',
  'Ku-Klux Klan',
  'Send weapons to Israel',
  'Vladimir Putin',
  'Natzies',
  'Pedophiles',
  'NATO',
  'Sex change for children',
  'Heroin',
  'USA',
  'Russia',
  'China',
];
