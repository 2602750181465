<style scoped>
</style>
<template>
  <div style="width: 100%; max-width: 550px; margin-left: auto; margin-right: auto;">
    <br>
    <br>
    <h2 id="intro"></h2>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <button @click="$router.push('/a')" style="float: right; width: 200px; height: 50px; font-size: 25px;">Next</button>

  </div>
</template>
<script>

import Typed from 'typed.js'

export default {
  name: 'Index',
  data() {
    return {
      a: ''
    }
  },
  isIMGErrored() {
    return this.$store.theImage === 'error'
  },

  computed: {
    polars() {
      return JSON.parse(JSON.stringify(polars))
    }
  },
  components: {
    //    mainmenu,
  },
  mounted() {
    var typed = new Typed('#intro', {
      strings: ['How does it make you feel?'],
      typeSpeed: 20
    });

  },
  methods: {
    next() {

    }
  }
}
</script>
