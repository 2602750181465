<template>
  <div id="main">
    <router-view></router-view>
    <div style="bottom: 100px; right: 100px; float: right; width: 200px; position: fixed;">
      <p v-for="(one, key) in $store.inventory" v-if="one" >
        {{ key }}:  {{ one }}
      </p>
  </div>
</template>
<script>
  import axios from 'axios'

  export default {
    name: "AppContainer",
    data () {
      return {
        initialLoad: false,
        admin: false
      }
    },
    mounted() {
//      this.getAndSetUser()
//      this.getAndSetCompany()
 //     this.getProducts()
    },
    components: {
    },
    methods: {
      getAndSetUser: function () {
        let t = this
        axios
          .get('/api/user')
          .then(({data}) => {
            this.$set(this.$store, 'user', data.user || {})
            if (data.user && data.user.roles && data.user.roles.indexOf('admin') > -1) {
              t.admin = true
            }
          })
          .catch((error) => {
            t.$router.push('/login')
            console.log(error)
          })
      },
      getAndSetCompany: function () {
        let t = this
        axios
          .get('/api/company')
          .then(({data}) => {
            this.$set(this.$store, 'company', data || {})
            if (data.integration) {
              const integration = data.integration
              if (integration === 'merit' || integration === '360ksiegowosc') {
                this.getUnits()
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getUnits: function () {
        axios
          .get('/api/unit')
          .then(({data}) => {
            if (data.length === 0) {
              this.pullUnits()
            } else {
              this.$set(this.$store, 'unit', data || [])
            }
          })
          .catch((error) => {
            console.log(error)
          })
      },
      getProducts: function () {
        axios
          .get('/api/product')
          .then(({data}) => {
            this.$set(this.$store, 'products', data || [])
          })
          .catch((error) => {
            console.log(error)
          })
      },
      pullUnits: function () {
        const t = this
        axios
          .get('/api/unit/pull')
          .then(({data}) => {
            t.$set(t.$store, 'unit', data || [])
          })
          .catch((error) => {
            console.log(error)
          })
      },
      cancel () {
        this.$store.newProduct = {}
        this.$store.newSell = {}
        this.$router.push('/')
      },
    }
  }
</script>

<style>

html {
  height: 100%;
}

body {
  font-family: Roboto, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

pre {
  text-align: left;
  white-space: pre-line;
  font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
  margin-bottom: 10px;
  overflow: auto;
  width: auto;
  padding: 5px;
  background-color: #eee;
  width: 650px!ie7;
  padding-bottom: 20px!ie7;
  max-height: 600px;
  font-size: 15px;
}

pre.small {
  white-space: pre;
  font-size: 12px;
}


</style>
