<template>
  <div>
    <div>
      404
    </div>
  </div>
</template>
<script>
  export default {
    data () {
      return {}
    }
  }
</script>
