import E404 from "./components/E404"
import Index from "./components/Index"
import Alias from "./components/Alias"
import Quest from "./components/Quest"
import Result from "./components/Result"
import Invite from "./components/Invite"
import Start from "./components/Start"

const routes = [
  {
    path: "*",
    component: E404,
  },
  {
    path: "/",
    component: Index,
  },
  {
    path: "/a",
    component: Alias,
  },
  {
    path: "/quest/:id/:order",
    component: Quest,
  },
  {
    path: "/results/:id",
    component: Result,
  },
  {
    path: "/invite/:id",
    component: Invite,
  },
  {
    path: "/invite/:id/:user",
    component: Invite,
  },
  {
    path: "/start/quest/:id/:username",
    component: Start,
  },
];

export default routes;
