<style scoped>
</style>
<template>
  <div style="width: 100%; max-width: 550px; margin-left: auto; margin-right: auto;">
    <br>
    <h2 id="question"></h2>
    <br>
    <br>
    <br>
    <button @click="answer('😊')" style="float: right; margin-right: 15px; width: 40%; height: 50px; font-size: 35px;">😊</button>
    <button @click="answer('😟')" style="float: left; margin-left: 15px; width: 40%; height: 50px; font-size: 35px;">😟</button>
  </div>
</template>
<script>

import Typed from 'typed.js'
import axios from 'axios'

export default {
  name: 'Index',
  data() {
    return {
      a: ''
    }
  },
  computed: {
    polars() {
      return JSON.parse(JSON.stringify(polars))
    },
    order() {
      return this.$route.params
    },
    id() {
      return this.$route.params.id
    },
    user() {
      return this.$store.user || localStorage.getItem('user')
    }
  },
  components: {
    //    mainmenu,
  },
  mounted() {
    this.makeTextGo()
  },
  methods: {
    makeTextGo () {
    const polar = String(this.$store.polars[this.order.order])
    const theQ = polar + '?'
    var typed = new Typed('#question', {
      strings: [theQ],
      typeSpeed: 30,
      showCursor: false
    })
    },
    answer(answer) {
      const t = this
      let payload = {
        q: this.$store.polars[this.order.order],
        a: answer,
        id: this.id,
        n: this.user
      }
      axios.post('/answer', payload)
        .then(function (data) {
          const next = Number(t.order.order) + 1
          const all = t.$store.polars.length
          if (next >= all) {
            const url = '/results/' + t.id + '/'
            t.$router.push(url)
          } else {
            const url = '/quest/' + t.id + '/' + next
            t.$router.push(url)
          }
        })
        .catch(function (err) {
          alert(err)
        })
    }
  },
  watch: {
    order() {
      this.makeTextGo()
    }
  }
}
</script>
