<style scoped>
</style>
<template>
  <div style="width: 100%; max-width: 550px; margin-left: auto; margin-right: auto; padding-left: 15px; padding-right: 15px;">
    <h2>
      Invite
    </h2>
    <br><br>
    Share this link: <br>
    <a :href="'https://wibes.ee/#/start/quest/' + id + '/' + user">
      https://wibes.ee/#/start/quest/{{ id }}/{{ user }}
    </a>
    <br>
    <br>
    <br>
    <br>
    <br>
    Results: <br>
    <a :href="'https://wibes.ee/#/results/' + id">
      https://wibes.ee/#/results/{{ id }}
    </a>
  </div>
</template>
<script>

import Typed from 'typed.js'
import axios from 'axios'
import polars from './polars'
import oneline from './Oneline'

export default {
  name: 'Index',
  data() {
    return {
      data: ''
    }
  },
  computed: {
    polars() {
      return JSON.parse(JSON.stringify(polars))
    },
    id() {
      return this.$route.params.id
    },
    user() {
      return this.$store.user || localStorage.getItem('user')
    }
  },
  components: {
    oneline,
  },
  mounted() {
    // this.get()
  },
  methods: {
    frontPage () {
      this.$router.push('/')
    },
    invite () {
      this.$router.push('/')
    },
  },
}
</script>
