<style scoped>
</style>
<template>
  <div style="width: 100%; max-width: 550px; margin-left: auto; margin-right: auto;">
    <br>
    <h4 id="invite" style="color: #999;"></h4>
    <br>
    <h2 id="intro"></h2>
    <br>
    <br>
    <br>
    <br>
    <br>
    <br>
    <button @click="$router.push('/a?id=' + id)" style="float: right; width: 200px; height: 50px; font-size: 25px;">Next</button>

  </div>
</template>
<script>

import Typed from 'typed.js'

export default {
  name: 'Index',
  data() {
    return {
      a: ''
    }
  },
  isIMGErrored() {
    return this.$store.theImage === 'error'
  },

  computed: {
    polars() {
      return JSON.parse(JSON.stringify(polars))
    },
    id() {
      return this.$route.params.id
    },
    username() {
      return this.$route.params.username
    }

  },
  components: {
    //    mainmenu,
  },
  mounted() {

    let prompt = 'How does it make you feel?'
    let invite = false
    if (this.username && this.username !== '') {
      invite = this.username + ' invites you to play a game:'
    }

    var typed = new Typed('#invite', {
      strings: [invite],
      typeSpeed: 20,
      showCursor: false
    })

    setTimeout(function() {
      var typed = new Typed('#intro', {
        strings: [prompt],
        typeSpeed: 20
      })
    }, 1500)

  },
  methods: {
    next() {

    }
  }
}
</script>
