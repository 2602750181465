<style scoped>
</style>
<template>
  <div style="width: 100%; max-width: 550px; margin-left: auto; margin-right: auto;">
    <br>
    <br>
    <h2>Your alias:</h2>
    <br>
    <input ref="username" v-model="name" style="height: 40px; font-size: 20pt; text-align: center; width: 100%;">
    <br>
    <br>
    <button @click="create()" style="float: right; width: 200px; height: 50px; font-size: 25px;">Next</button>
  </div>
</template>
<script>

import axios from 'axios'

import Typed from 'typed.js'

export default {
  name: 'Index',
  data() {
    return {
      name: ''
    }
  },
  computed: {
    polars() {
      return JSON.parse(JSON.stringify(polars))
    },
    id () {
      return this.$route.query.id
    }
  },
  components: {
    //    mainmenu,
  },
  mounted() {
    this.$refs.username.focus()
  },
  methods: {
    create() {
      if (this.id) {
        // GO TO EXISTING
        this.$store.id = this.id
        if (this.name.length === 0) {
          this.$store.user = 'anon_' + this.hash()
        } else {
          this.$store.user = this.name
        }
        localStorage.setItem('user', this.$store.user)
        this.$router.push('/quest/' + this.id + '/0')
      } else {
        // CREATE NEW
        const t = this
        const payload = { n: this.name }
        axios.post('/create', payload)
          .then(function(data) {
            const id = data.data.id
            t.$store.id = id
            if (t.name.length === 0) {
              t.$store.user = 'anon_' + t.hash()
            } else {
              t.$store.user = t.name
            }
            localStorage.setItem('user', t.$store.user)
            t.$router.push('/quest/' + id + '/0')
          })
          .catch(function(err) {

          })
      }
    },
    hash () {
     let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
     let hash = ''

     for (let i = 0; i < 4; i++) {
       hash += characters.charAt(Math.floor(Math.random() * characters.length));
     }

     return hash
    }
  }
}
</script>
